<template>
  <div>
    <apexchart
      :options="chartOptions"
      :series="series"
      type="bar"
      height="350"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    asup: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Filter and group alerts by time range and severity
    alertGroups() {
      const now = new Date(this.$options.filters.formatDateTimeTz(new Date(), this.asup.timezone))
      const alerts = this.asup.details.alerts.filter(alert => alert.category === 'ANOMALY_DETECTION')

      return {
        '24h': this.groupAlertsBySeverity(alerts.filter(alert => {
          const alertDate = new Date(this.$options.filters.formatDateTimeTz(alert.postedTime, this.asup.timezone))
          return (now - alertDate) <= 24 * 60 * 60 * 1000
        })),
        '3d': this.groupAlertsBySeverity(alerts.filter(alert => {
          const alertDate = new Date(this.$options.filters.formatDateTimeTz(alert.postedTime, this.asup.timezone))
          return (now - alertDate) <= 3 * 24 * 60 * 60 * 1000
        })),
        '7d': this.groupAlertsBySeverity(alerts.filter(alert => {
          const alertDate = new Date(this.$options.filters.formatDateTimeTz(alert.postedTime, this.asup.timezone))
          return (now - alertDate) <= 7 * 24 * 60 * 60 * 1000
        })),
      }
    },

    // Format data for ApexCharts series
    series() {
      return [
        {
          name: 'Critical',
          data: [
            this.alertGroups['24h'].critical,
            this.alertGroups['3d'].critical,
            this.alertGroups['7d'].critical,
          ],
        },
        {
          name: 'Warning',
          data: [
            this.alertGroups['24h'].warning,
            this.alertGroups['3d'].warning,
            this.alertGroups['7d'].warning,
          ],
        },
        {
          name: 'Informational',
          data: [
            this.alertGroups['24h'].info,
            this.alertGroups['3d'].info,
            this.alertGroups['7d'].info,
          ],
        },
      ]
    },

    // Chart configuration
    chartOptions() {
      return {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
          },
        },
        xaxis: {
          categories: ['Last 24 Hours', 'Last 3 Days', 'Last 7 Days'],
        },
        yaxis: {
          title: {
            text: 'Number of Alerts',
          },
        },
        colors: [$themeColors.danger, $themeColors.warning, $themeColors.info],
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        dataLabels: {
          enabled: false,
        },
      }
    },
  },
  methods: {
    // Helper method to group alerts by severity
    groupAlertsBySeverity(alerts) {
      return {
        critical: alerts.filter(alert => alert.severity === 'CRITICAL').length,
        warning: alerts.filter(alert => alert.severity === 'WARNING').length,
        info: alerts.filter(alert => alert.severity === 'INFORMATIONAL').length,
      }
    },
  },
}
</script>
